import React, { useEffect, useMemo, useState } from "react";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import { Button, Column, Grid, Row } from "carbon-components-react";
import { useHistory, useParams } from "react-router-dom";
import { useDeletePaymentOrchestrationItem, useDuplicatePaymentOrchestrationItem, useGetPaymentOrchestrationItem, useUpdatePaymentOrchestrationItem } from "modules/routingRules/apiHooks";
import Loader from "components/atoms/Loader";
import PaymentOrchestrationTreeViewRule from "modules/routingRules/components/RoutingRulesTreeView";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useCreateEditConfig } from "../constantHooks";
import Divider from "components/atoms/Divider";
import { createEditOrchestratorValidation } from "../validation";
import { Copy16, Delete16 } from "@carbon/icons-react";
import { useConfirmModal } from "modules/modal/predefinedModals";
import { useGetWarningSaveDataWithoutDefaultStartegy } from "../hooks";
import { getUrl } from "system/helpers/helperFunctions";
import { APP_ROUTES } from "system/router/constants";
import { MerchantAndRuleStateType } from "../types";

const Update: React.FC = () => {
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { data , isLoading } = useGetPaymentOrchestrationItem(routeParams.id)
  const { mutate: onUpdate } = useUpdatePaymentOrchestrationItem()
  const { onConfirm } = useConfirmModal();
  const { checkDefaultStrategyAndDisplayWarningOrSaveData } = useGetWarningSaveDataWithoutDefaultStartegy()
  const { mutateAsync: onDeleteAsync } = useDeletePaymentOrchestrationItem();
  const { mutateAsync: onDuplicateAsync } = useDuplicatePaymentOrchestrationItem();
  const history = useHistory()

  const merchantAndRuleState = useMemo((): MerchantAndRuleStateType => {
    return {
      selectedMerchantId: data?.merchant.id || null,
      selectedRuleType: data?.ruleType || null
    }
  }, [data])

  const formConfig = useCreateEditConfig({isEdit: true, merchantAndRuleState})

const onDeleteWithConfirm = ({ id }: any) => {
  onConfirm({
    onOk: async () => {
      await onDeleteAsync({ id })
      history.goBack()
     },
    bodyText: _t("delete_{ruleName}_for_{merchantName}_{paymentMethodName}",{
      ruleName: data?.ruleName || '',
      merchantName: data?.merchant.name || '',
      paymentMethodName: data?.paymentMethod || '',
    }),
    onOkText: _t("delete"),
    onOkKind: "danger",
    renderIcon: Delete16,
  });
  };

const onDuplicateWithConfirm = ({ id }: any) => {
    onConfirm({ onOk: async () => {
    const response  = await onDuplicateAsync({ id })
      
    const link = getUrl(APP_ROUTES.paymentOrchestration.routingRules.update, response?.id);
    history.replace(link);
    },
    bodyText: _t("duplicate_{ruleName}_for_{merchantName}_{paymentMethodName}", {
      ruleName: data?.ruleName || '',
      merchantName: data?.merchant.name || '',
      paymentMethodName: data?.paymentMethod || '',
    })
  });
  };

  const [ rules, setRules ] = useState({})

  useEffect(() => {
    if (data) {
      const regex = /,\s*([\]}])/g;
      const fixedJson = data.rules.replace(regex, "$1");
      const json = JSON.parse(fixedJson);
      setRules(json)
    }
  }, [data])

  const onSubmit = (formData: any) => {
    const normalize = { ...formData }
    normalize.rules = JSON.stringify(rules)
    checkDefaultStrategyAndDisplayWarningOrSaveData(rules, () => onUpdate(normalize))
  }

  if (isLoading) {
    return <Loader formOverlay />;
  }


  return (
    <Grid style={{ paddingLeft: "1rem" }} narrow>
      <Row>
        <Column
          lg={{ span: 16, offset: 0 }}
          md={{ span: 16, offset: 0 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card
            title={_t("{form_name}", {
              form_name: data?.ruleName || 'No name'
            })}
            extra={
              <>
              <Button kind="tertiary" renderIcon={Copy16} onClick={() => onDuplicateWithConfirm({id: routeParams.id})}>
                {_t("duplicate_rule")}
              </Button>
              <Button kind="tertiary" renderIcon={Delete16} onClick={() => onDeleteWithConfirm({id: routeParams.id})}>
                {_t("delete_rule_set")}
              </Button>
              </>
            }
          >
            {_t("rule_set_configuration")}
            
            <FormBuilder
              showSubmit={false}
              formItemsConfig={formConfig}
              formProps={{
                onSubmit: onSubmit,
                initialValues: { ...data, active: data?.active === 'true' } || {},
                validationSchema: createEditOrchestratorValidation(_t)
              }}
            />

            <PaymentOrchestrationTreeViewRule
              value={rules}
              onChange={setRules}
              merchantAndRuleState={merchantAndRuleState}
            />
            <div className='tree-view-rule__divider'>
            <Divider/>
            </div>
          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Update;
