import { useMutation, useQuery, useQueryClient } from "react-query";
import { useApiRequest } from "system/api/hooks";
import { 
  API_COPY_PAYMENT_ORCHESTRATION_ITEM,
  API_CREATE_PAYMENT_ORCHESTRATION_ITEM,
  API_DELETE_PAYMENT_ORCHESTRATION_ITEM,
  API_GET_BINS_PARAMS,
  API_GET_ORCHESTRATED_GATEWAYS_BY_MERCHANT,
  API_GET_PAYMENT_METHODS_WITH_ID,
  API_GET_PAYMENT_ORCHESTRATION,
  API_GET_PAYMENT_ORCHESTRATION_ITEM,
  API_GET_TEMPLATES_TYPES,
  API_MERCHANT_PAYMENT_METHODS
} from "system/api/apiUrls";
import { generatePath } from "react-router-dom";
import { useApiErrors } from "system/helpers/apiErrorHelper";
import { useSuccessToast } from "system/helpers/hooks";
import { ErrorType } from "system/helpers/types";
import { BinsParamsType, PaymentGatewaysDataType, PaymentMethodsDataType, PaymentOrchestrationItemType, TemplatesTypesParamDataType } from "./types";
import { generateUrlQuery } from "system/helpers/helperFunctions";


const getPaymentOrchestrationKey = "getPaymentOrchestrationKey";
const getPaymentOrchestrationItemKey = "getPaymentOrchestrationItemKey";
const getPaymentMethodsWithIdKey = "getPaymentMethodsWithIdKey";
const getBinsParamsKey = "getBinsParamsKey";
const getPaymentGatewaysByMerchant = "getPaymentGatewaysByMerchant";
const getMerchantPaymentMethods = "getMerchantPaymentMethods";
const getTemplatesTypesKey = "getTemplatesTypesKey";


export const useGetPaymentOrchestration = (filters = {}) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getPaymentOrchestrationKey, filters],
    (): Promise<PaymentOrchestrationItemType[]> =>
      apiRequest({
        url: generateUrlQuery(API_GET_PAYMENT_ORCHESTRATION, filters),
        method: "get",
      })
  );
};

export const useGetPaymentOrchestrationItem = (id: number) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getPaymentOrchestrationItemKey, id],
    (): Promise<PaymentOrchestrationItemType> =>
      apiRequest({
        url: generatePath(API_GET_PAYMENT_ORCHESTRATION_ITEM, { id }),
        method: "get",
      })
  );
};

export const useUpdatePaymentOrchestrationItem = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_GET_PAYMENT_ORCHESTRATION_ITEM, {
          id: data.id,
        }),
        method: "put",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getPaymentOrchestrationItemKey);
        queryClient.invalidateQueries(getPaymentOrchestrationKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useCreatePaymentOrchestrationItem = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: API_CREATE_PAYMENT_ORCHESTRATION_ITEM,
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getPaymentOrchestrationItemKey);
        queryClient.invalidateQueries(getPaymentOrchestrationKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};
export const useDuplicatePaymentOrchestrationItem = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url:generatePath(API_COPY_PAYMENT_ORCHESTRATION_ITEM, {
          id: data.id,
        }) ,
        method: "post",
        data: data,
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getPaymentOrchestrationItemKey);
        queryClient.invalidateQueries(getPaymentOrchestrationKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useGetPaymentMethodsWithId = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getPaymentMethodsWithIdKey],
    (): Promise<any> =>
      apiRequest({
        url: API_GET_PAYMENT_METHODS_WITH_ID,
        method: "get",
      })
  );
};

export const useDeletePaymentOrchestrationItem = () => {
  const [apiRequest] = useApiRequest();
  const queryClient = useQueryClient();
  const { apiErrors } = useApiErrors();
  const { apiSuccessMessage } = useSuccessToast();
  return useMutation(
    (data: any) =>
      apiRequest({
        url: generatePath(API_DELETE_PAYMENT_ORCHESTRATION_ITEM, {
          id: data.id,
        }),
        method: "delete",
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getPaymentOrchestrationKey);
        apiSuccessMessage();
      },
      onError: (err: ErrorType) => apiErrors(err),
    }
  );
};

export const useGetBinsParams = () => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getBinsParamsKey],
    (): Promise<BinsParamsType> =>
      apiRequest({
        url: API_GET_BINS_PARAMS,
        method: "get",
      })
  );
};

export const useGetPaymentGatewaysByMerchant = (merchantId: number | null) => {
  const [apiRequest] = useApiRequest();
  return useQuery(
    [getPaymentGatewaysByMerchant, merchantId],
    (): Promise<PaymentGatewaysDataType> =>
    apiRequest({
      url: generatePath(API_GET_ORCHESTRATED_GATEWAYS_BY_MERCHANT, { merchantId: `${merchantId}`}),
      method: "get",
    }),
    { enabled: !!merchantId }
    );
  };
  
  export const useGetMerchantPaymentMethods = (id: number | null) => {
    const [apiRequest] = useApiRequest();
    return useQuery(
      [getMerchantPaymentMethods, id],
      (): Promise<PaymentMethodsDataType> =>
      apiRequest({
        url: generatePath(API_MERCHANT_PAYMENT_METHODS, { id: `${id}`}),
        method: "get",
      }),
      { enabled: !!id }
      );
    };

    export const useGetTemplatesTypes = () => {
      const [apiRequest] = useApiRequest();
      return useQuery(
        [getTemplatesTypesKey],
        (): Promise<TemplatesTypesParamDataType> =>
          apiRequest({
            url: API_GET_TEMPLATES_TYPES,
            method: "get",
          })
      );
    };