import { useEffect, useMemo, useState } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { CreateEditFormConfigParams, TransactionType } from "./types";
import { useModuleData } from "./hooks";
import { TableColumnType } from "modules/table/types";


export const useTransactionType = () => {
  const { _t } = useTrans();
  return [
    { label: _t("deposit"), value: "DEPOSIT" },
    { label: _t("withdrawal"), value: "WITHDRAWAL" },
  ];
};

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("id"),
        accessor: "id",
        id: "id1",
        disableSortBy: true,
        width: 60,
        mobileVisible: true,
      },
      {
        Header: _t("merchant"),
        accessor: "merchantName",
        id: "merchantName",
        disableSortBy: true,
        mobileVisible: true,
      },
      {
        Header: _t("payment_gateway"),
        accessor: "paymentGatewayCode",
        id: "paymentGatewayCode",
        disableSortBy: true,
        width: 230,
        mobileVisible: true,
        isMobileTitle: true
      },
      {
        Header: _t("slack_channel"),
        accessor: "channelName",
        id: "channelName",
        disableSortBy: true,
        minWidth: 190,
        mobileVisible: true,
      },
      {
        Header: _t("success_rate_check_length"),
        accessor: "successRateCheckLength",
        id: "successRateCheckLength",
        disableSortBy: true,
        minWidth: 190,
        mobileVisible: true,
      },
      {
        Header: _t("success_rate_threshold"),
        accessor: "successRateThreshold",
        id: "successRateThreshold",
        disableSortBy: true,
        minWidth: 190,
        mobileVisible: true,
      },
      {
        Header: _t("errors_in_row_threshold"),
        accessor: "errorsInARowThreshold",
        id: "errorsInARowThreshold",
        disableSortBy: true,
        minWidth: 190,
        mobileVisible: true,
      },
      {
        Header: _t("errors_in_row_check_enabled"),
        accessor: "errorsInARowCheckEnabled",
        id: "errorsInARowCheckEnabled",
        disableSortBy: true,
        minWidth: 220,
        mobileVisible: true,
        Cell: (props: any) => {
          return <>{`${props.row.original.errorsInARowCheckEnabled}`}</>;
        },
      },
      {
        Header: _t("success_rate_check_enabled"),
        accessor: "successRateCheckEnabled",
        id: "successRateCheckEnabled",
        disableSortBy: true,
        minWidth: 220,
        mobileVisible: true,
        Cell: (props: any) => {
          return <>{`${props.row.original.successRateCheckEnabled}`}</>;
        },
      },
      {
        Header: _t("created_date"),
        accessor: "createdDate",
        id: "createdDate",
        disableSortBy: true,
        minWidth: 230,
        mobileVisible: true,
      },
      {
        Header: _t("updated_date"),
        accessor: "updatedDate",
        id: "updatedDate",
        disableSortBy: true,
        minWidth: 230,
        mobileVisible: true,
      },
      {
        maxWidth: 40,
        Header: "",
        id: "UpdateDeleteMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.filterValue({
        componentProps: {
          showSearch: true,
          type: "submit",
        },
        columnParams: {
          md: 6,
          lg: 6,
        },
      }),
    ];
  }, []);
  return formConfig;
};

export const useCreateEditConfig = (
  params: CreateEditFormConfigParams
): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const { isEdit, initialValues } = params
  const [ merchantId, setMerchantId ] = useState<number | undefined>(undefined)
  const [ transactionType, setTransactionType ] = useState<TransactionType>()
  const [ isAllGatewaysDisplay, setIsAllGatewaysDisplay ] = useState(false);  
  const {
    paymentGatewaysOptionsAll,
    paymentGatewaysByMerchant,
    merchantsOptions,
    merchantSlackChannelOptions
  } = useModuleData(isEdit, merchantId);

  useEffect(() => {
    if (isEdit) {
      setMerchantId(initialValues?.merchantId)
      setTransactionType(initialValues?.transactionType.toLowerCase())
    }
  }, [initialValues])

  const showAllProvidersButton = useMemo(() => {
    return isEdit ? [] : [
      fields.formFields.getAllGateways({
        componentProps:{
          value: isAllGatewaysDisplay,
          onFieldChange: (value: any, values: any, form: any) => {
            form.setFieldValue('paymentGatewayCode', "")
            form.setFieldTouched('paymentGatewayCode', false);
            setIsAllGatewaysDisplay(value)
          },
        },
      })]
  },[isEdit])
  const transactionTypeOptions = useTransactionType();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.transactionType({
        componentProps: {
          items: transactionTypeOptions,
          disabled: isEdit,
          onFieldChange: (value: any, values: any, form: any) => {
            setTransactionType(value.toLowerCase() as TransactionType)
            form.setFieldValue('paymentGatewayCode', "")
            form.setFieldTouched('paymentGatewayCode', false);
          }
        },
      }),
      fields.formFields.merchantIdSingle({
        name: "merchant",
        componentProps: {
          items: merchantsOptions,
          light: false,
          disabled: isEdit,
          onFieldChange: (value: any, values: any, form: any) => {
            setMerchantId(value.id);
            form.setFieldValue('paymentGatewayCode', "")
            form.setFieldTouched('paymentGatewayCode', false);
          },
        },
      }),
      ...showAllProvidersButton,
      fields.formFields.paymentGatewayCode({
        componentProps: {
          items: isAllGatewaysDisplay ? paymentGatewaysOptionsAll : paymentGatewaysByMerchant?.[transactionType as TransactionType],
          light: false,
          whiteBackground: true,
          wrapClassPrefix: 'dropdown-full-width',
          withTitleText: true,
          previewSelectedItems: true,
          disabled: isEdit || !transactionType || !merchantId,
        },
      }),
      fields.formFields.merchantSlackChannelSelect({
        name: "channel",
        componentProps: {
          items: merchantSlackChannelOptions,
          light: false,
        },
      }),
      fields.formFields.successRateCheckLength(),
      fields.formFields.successRateThreshold(),
      fields.formFields.errorsInARowThreshold(),
      fields.formFields.successRateCheckEnabled({
        columnParams: {
          lg: 8,
          md: 8,
        },
        emptySpace: {
          sm: { span: 16 },
          lg: { span: 16 },
          md: { span: 16 },
        },
      }),
      fields.formFields.errorsInARowCheckEnabled({
        columnParams: {
          lg: 8,
          md: 8,
        },
      }),
      fields.general.divider(),
      fields.general.saveBtn(),
    ];
  }, [
    paymentGatewaysOptionsAll,
    paymentGatewaysByMerchant,
    merchantsOptions,
    merchantSlackChannelOptions
  ]);
  return formConfig;
};
