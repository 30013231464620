import React from "react";
import { Route, Switch } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import ActionLog from "pages/actionLog";
import Login from "pages/auth/login";
import VerifiedPage from "pages/auth/verified";
import ForgotPassword from "pages/auth/forgotPassword";
import Merchants from "pages/merchants";
import SubMerchants from "pages/merchants/subMerchants";
import MerchantsBalance from "pages/merchantsBalance";
import MerchantsBalanceV2Page from "pages/merchantsBalanceV2";
import MonitoringPage from "pages/monitoring";
import MonitoringCreatePage from "pages/monitoring/create";
import MonitoringUpdatePage from "pages/monitoring/update";
import MonitoringMerchantPage from "pages/monitoringMerchant";
import MonitoringMerchantCreatePage from "pages/monitoringMerchant/create";
import MonitoringMerchantUpdatePage from "pages/monitoringMerchant/update";
import AlertsPage from "pages/alerts";
import AlertsCreatePage from "pages/alerts/create";
import AlertsUpdatePage from "pages/alerts/update";
import PageNotFound from "pages/pageNotFound";
import Roles from "pages/roles";
import AnalyticsPage from "pages/analytics";
import Transactions from "pages/transactions";
import UpdateTransactionStatus from "pages/transactions/update";
import Users from "pages/users";
import ChangePasswordPage from "pages/admin/changePassword";
import PrivateRoute from "./PrivateRoute";
import UsersCreatePage from "pages/users/create";
import UsersUpdatePage from "pages/users/update";
import Translations from "pages/translations";
import TranslationsCreatePage from "pages/translations/create";
import TranslationsUpdatePage from "pages/translations/update";
import RolesCreatePage from "pages/roles/create";
import RolesUpdatePage from "pages/roles/update";
import MerchantCreatePage from "pages/merchants/create";
import CreateSubMerchantPage from "pages/merchants/createSubMerchant";
import MerchantUpdatePage from "pages/merchants/update";
import MerchantPaymentsPage from "pages/merchantPayments";
import MerchantPaymentsCreatePage from "pages/merchantPayments/create";
import MerchantPaymentsUpdatePage from "pages/merchantPayments/update";
import MerchantBlackListPage from "pages/merchantBlackList";
import MerchantBlackListCreatePage from "pages/merchantBlackList/create";
import MerchantBlackListUpdatePage from "pages/merchantBlackList/update";
import MerchantWhiteListPage from "pages/merchantWhiteList";
import MerchantWhiteListCreatePage from "pages/merchantWhiteList/create";
import MerchantWhiteListUpdatePage from "pages/merchantWhiteList/update";
import CredentialsPage from "pages/credentials";
import CredentialsCreatePage from "pages/credentials/create";
import CredentialsUpdatePage from "pages/credentials/update";
import CredentialInfoPage from "pages/credentials/credentialInfo";
import ResetPassword from "pages/auth/resetPassword";
import SettingsPage from "pages/settings";
import SettingsCreatePage from "pages/settings/create";
import SettingsUpdatePage from "pages/settings/update";
import SettingInfoPage from "pages/settings/settingInfo";
import GateWayWhiteListPage from "pages/gateWayWhiteList";
import GateWayWhiteListCreatePage from "pages/gateWayWhiteList/create";
import GateWayWhiteListUpdatePage from "pages/gateWayWhiteList/update";
import RequestHistoryPage from "pages/requetsHistory";
import { AccessToType } from "system/helpers/types";
import PendingTransactionsMonitoringPage from "pages/pendingTransactionsMonitoring";
import PendingTransactionsMonitoringCreatePage from "pages/pendingTransactionsMonitoring/create";
import PendingTransactionsMonitoringUpdatePage from "pages/pendingTransactionsMonitoring/update";
import SettingsDuplicatePage from "pages/settings/duplicate";
import MerchantsBalanceDetailsPage from "pages/merchantsBalanceDetails";
import MerchantsBalanceDetailsCreatePage from "pages/merchantsBalanceDetails/create";
import MerchantsFeesPage from "pages/merchantsBalanceV2/merchantsFees";
import MerchantsFeesUpdatePage from "pages/merchantsBalanceV2/merchantsFees/update";
import MerchantsFeesCreatePage from "pages/merchantsBalanceV2/merchantsFees/create";
import MerchantConfigurationPage from "pages/merchantsBalanceV2/merchantConfiguration";
import BalanceHistoryPage from "pages/merchantsBalanceV2/balanceHistory";
import MerchantConfigurationUpdatePage from "pages/merchantsBalanceV2/merchantConfiguration/update";
import MerchantConfigurationCreatePage from "pages/merchantsBalanceV2/merchantConfiguration/create";
import SwaggerDocumentationPage from "pages/swaggerDocumentation";
import RoutingRulesPage from "pages/paymentOrchestartion/routingRules";
import ProviderConstraintsPage from "pages/paymentOrchestartion/providerConstraints";
import CoverageReportPage from "pages/paymentOrchestartion/coverageReport";
import RoutingRulesUpdatePage from "pages/paymentOrchestartion/routingRules/update";
import RoutingRulesCreatePage from "pages/paymentOrchestartion/routingRules/create";
import PaymentInstrumentManagementPage from "pages/paymentInstrumentManagement";
import StatusReasonsPage from "pages/statusReasons";
import StatusReasonsCreatePage from "pages/statusReasons/create";
import StatusReasonsUpdatePage from "pages/statusReasons/update";
import StatusReasonsConnectionPage from "pages/statusReasonsConnection";
import StatusReasonsConnectionUpdatePage from "pages/statusReasonsConnection/update";
import StatusReasonsConnectionCreatePage from "pages/statusReasonsConnection/create";
import MerchantPendingTransactionsMonitoringPage from "pages/merchantPendingTransactionsMonitoring";
import MerchantPendingTransactionsMonitoringCreatePage from "pages/merchantPendingTransactionsMonitoring/create";
import MerchantPendingTransactionsMonitoringUpdatePage from "pages/merchantPendingTransactionsMonitoring/update";
import SetupSlackChannelPage from "pages/merchants/setupSlackChannel";
import MerchantSuccessRateMonitoringPage from "pages/merchantSuccessRateMonitoring";
import MerchantSuccessRateMonitoringCreatePage from "pages/merchantSuccessRateMonitoring/create";
import MerchantSuccessRateMonitoringUpdatePage from "pages/merchantSuccessRateMonitoring/update";


type RouteType = {
  path: string;
  component: any;
  exact?: boolean;
  privateRoute?: boolean;
  accessTo?: AccessToType;
};

const routes: RouteType[] = [
  {
    path: APP_ROUTES.login,
    component: Login,
    privateRoute: false,
    accessTo: "",
  },
  {
    path: APP_ROUTES.verified,
    component: VerifiedPage,
    privateRoute: false,
    accessTo: "",
  },
  {
    path: APP_ROUTES.forgotPassword,
    component: ForgotPassword,
    privateRoute: false,
    accessTo: "",
  },
  {
    path: APP_ROUTES.resetPassword,
    component: ResetPassword,
    privateRoute: false,
    accessTo: "",
  },
  {
    path: APP_ROUTES.admin.change_password,
    component: ChangePasswordPage,
    exact: true,
    privateRoute: true,
    accessTo: "",
  },
  {
    path: APP_ROUTES.swagger.index,
    component: SwaggerDocumentationPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_SUPERADMIN",
  },
  {
    path: APP_ROUTES.actionLog,
    component: ActionLog,
    privateRoute: true,
    accessTo: "ROLE_SUPERADMIN",
  },
  //merchants
  {
    path: APP_ROUTES.merchants.index,
    component: Merchants,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_VIEW_MERCHANTS",
  },
  {
    path: APP_ROUTES.merchants.create,
    component: MerchantCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_CREATE_MERCHANTS",
  },
  {
    path: APP_ROUTES.merchants.update,
    component: MerchantUpdatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANTS",
  },
  {
    path: APP_ROUTES.merchants.payments.index,
    component: MerchantPaymentsPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_VIEW_MERCHANT_PM",
  },
  {
    path: APP_ROUTES.merchants.payments.create,
    component: MerchantPaymentsCreatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_PM",
  },
  {
    path: APP_ROUTES.merchants.payments.update,
    component: MerchantPaymentsUpdatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_PM",
  },
  {
    path: APP_ROUTES.merchants.blacklist.index,
    component: MerchantBlackListPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_BLACKLIST",
  },
  {
    path: APP_ROUTES.merchants.blacklist.create,
    component: MerchantBlackListCreatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_BLACKLIST",
  },
  {
    path: APP_ROUTES.merchants.blacklist.update,
    component: MerchantBlackListUpdatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_BLACKLIST",
  },
  {
    path: APP_ROUTES.merchants.whitelist.index,
    component: MerchantWhiteListPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_WHITELIST",
  },
  {
    path: APP_ROUTES.merchants.whitelist.create,
    component: MerchantWhiteListCreatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_WHITELIST",
  },
  {
    path: APP_ROUTES.merchants.whitelist.update,
    component: MerchantWhiteListUpdatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_WHITELIST",
  },
  {
    path: APP_ROUTES.merchants.slackChannel.create,
    component: SetupSlackChannelPage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_SLACK_CHANNELS",
  },
  //end merchants
  //sub-merchants
  {
    path: APP_ROUTES.merchants.sub.index,
    component: SubMerchants,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_VIEW_SUBMERCHANT",
  },
  {
    path: APP_ROUTES.merchants.sub.create,
    component: CreateSubMerchantPage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_SUBMERCHANTS",
  },
  {
    path: APP_ROUTES.merchants.sub.update,
    component: MerchantUpdatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_SUBMERCHANTS",
  },
  {
    path: APP_ROUTES.merchants.sub.payments.index,
    component: MerchantPaymentsPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_SUBMERCHANTS",
  },
  {
    path: APP_ROUTES.merchants.sub.payments.create,
    component: MerchantPaymentsCreatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_SUBMERCHANTS",
  },
  {
    path: APP_ROUTES.merchants.sub.payments.update,
    component: MerchantPaymentsUpdatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_SUBMERCHANTS",
  },
  {
    path: APP_ROUTES.merchants.sub.blacklist.index,
    component: MerchantBlackListPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_SUBMERCHANTS",
  },
  {
    path: APP_ROUTES.merchants.sub.blacklist.create,
    component: MerchantBlackListCreatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_SUBMERCHANTS",
  },
  {
    path: APP_ROUTES.merchants.sub.blacklist.update,
    component: MerchantBlackListUpdatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_SUBMERCHANTS",
  },
  {
    path: APP_ROUTES.merchants.sub.whitelist.index,
    component: MerchantWhiteListPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_SUBMERCHANTS",
  },
  {
    path: APP_ROUTES.merchants.sub.whitelist.create,
    component: MerchantWhiteListCreatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_SUBMERCHANTS",
  },
  {
    path: APP_ROUTES.merchants.sub.whitelist.update,
    component: MerchantWhiteListUpdatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_SUBMERCHANTS",
  },
  // end sub-merchants
  /*  {
    path: APP_ROUTES.merchants.sub.subMerchants,
    component: SubMerchants,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_VIEW_SUBMERCHANT",
  },
  {
    path: APP_ROUTES.merchants.createSubMerchant,
    component: CreateSubMerchantPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_SUBMERCHANTS",
  },*/
  {
    path: APP_ROUTES.merchantsBalance,
    component: MerchantsBalance,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_VIEW_MERCHANT_BALANCE",
  },
  {
    path: APP_ROUTES.merchantsBalanceV2.index,
    component: MerchantsBalanceV2Page,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_VIEW_MERCHANT_BALANCE_V2", "ROLE_MODIFY_MERCHANT_BALANCE_V2"],
  },
  {
    path: APP_ROUTES.merchantsBalanceV2.merchantsFees.index,
    component: MerchantsFeesPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_BALANCE_V2",
  },
  {
    path: APP_ROUTES.merchantsBalanceV2.merchantsFees.update,
    component: MerchantsFeesUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_BALANCE_V2",
  },
  {
    path: APP_ROUTES.merchantsBalanceV2.merchantsFees.create,
    component: MerchantsFeesCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_BALANCE_V2",
  },
  {
    path: APP_ROUTES.merchantsBalanceV2.merchantConfiguration.index,
    component: MerchantConfigurationPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_BALANCE_V2",
  },
  {
    path: APP_ROUTES.merchantsBalanceV2.merchantConfiguration.update,
    component: MerchantConfigurationUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_BALANCE_V2",
  },
  {
    path: APP_ROUTES.merchantsBalanceV2.merchantConfiguration.create,
    component: MerchantConfigurationCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_BALANCE_V2",
  },
  {
    path: APP_ROUTES.merchantsBalanceV2.balanceHistory,
    component: BalanceHistoryPage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_BALANCE_V2",
  },
  {
    path: APP_ROUTES.merchantsBalanceV2.detailsInCurrency.index,
    component: MerchantsBalanceDetailsPage,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_VIEW_MERCHANT_BALANCE_V2", "ROLE_MODIFY_MERCHANT_BALANCE_V2"],
  },
  {
    path: APP_ROUTES.merchantsBalanceV2.detailsInCurrency.create,
    component: MerchantsBalanceDetailsCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_BALANCE_V2",
  },
  {
    path: APP_ROUTES.monitoring.index,
    component: MonitoringPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MONITORING",
  },
  {
    path: APP_ROUTES.monitoring.create,
    component: MonitoringCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MONITORING",
  },
  {
    path: APP_ROUTES.monitoring.update,
    component: MonitoringUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MONITORING",
  },
  {
    path: APP_ROUTES.pendingTransactionsMonitoring.index,
    component: PendingTransactionsMonitoringPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MONITORING",
  },
  {
    path: APP_ROUTES.pendingTransactionsMonitoring.create,
    component: PendingTransactionsMonitoringCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MONITORING",
  },
  {
    path: APP_ROUTES.pendingTransactionsMonitoring.update,
    component: PendingTransactionsMonitoringUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MONITORING",
  },
  {
    path: APP_ROUTES.monitoringMerchant.index,
    component: MonitoringMerchantPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MONITORING",
  },
  {
    path: APP_ROUTES.monitoringMerchant.create,
    component: MonitoringMerchantCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MONITORING",
  },
  {
    path: APP_ROUTES.monitoringMerchant.update,
    component: MonitoringMerchantUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MONITORING",
  },
  {
    path: APP_ROUTES.alerts.index,
    component: AlertsPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MONITORING",
  },
  {
    path: APP_ROUTES.alerts.create,
    component: AlertsCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MONITORING",
  },
  {
    path: APP_ROUTES.alerts.update,
    component: AlertsUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MONITORING",
  },
  {
    path: APP_ROUTES.roles.index,
    component: Roles,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_SUPERADMIN", "ROLE_MODIFY_ROLES"],
  },
  {
    path: APP_ROUTES.roles.create,
    component: RolesCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_SUPERADMIN", "ROLE_MODIFY_ROLES"],
  },
  {
    path: APP_ROUTES.roles.update,
    component: RolesUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_SUPERADMIN", "ROLE_MODIFY_ROLES"],
  },
  {
    path: APP_ROUTES.analytics,
    component: AnalyticsPage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_SUPERADMIN", "ROLE_VIEW_ANALYTICS"],
  },
  {
    path: APP_ROUTES.paymentOrchestration.routingRules.index,
    component: RoutingRulesPage,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_VIEW_ORCHESTRATOR_CONFIGURATION", "ROLE_SUPERADMIN"],
  },
  {
    path: APP_ROUTES.paymentOrchestration.routingRules.create,
    component: RoutingRulesCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_ORCHESTRATOR_CONFIGURATION", "ROLE_SUPERADMIN"],
  },
  {
    path: APP_ROUTES.paymentOrchestration.routingRules.update,
    component: RoutingRulesUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_ORCHESTRATOR_CONFIGURATION", "ROLE_SUPERADMIN"],
  },
  {
    path: APP_ROUTES.paymentOrchestration.providerConstraints.index,
    component: ProviderConstraintsPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_SUPERADMIN",
  },
  {
    path: APP_ROUTES.paymentOrchestration.coverageReport.index,
    component: CoverageReportPage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_SUPERADMIN",
  },
  {
    path: APP_ROUTES.transactions.index,
    component: Transactions,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_VIEW_TRANSACTION",
  },
  {
    path: APP_ROUTES.transactions.update,
    component: UpdateTransactionStatus,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_SUPERADMIN", "ROLE_CHANGE_TRANSACTION_STATUS"],
  },
  {
    path: APP_ROUTES.translations.index,
    component: Translations,
    exact: true,
    privateRoute: true,
    accessTo: [
      "ROLE_MODIFY_TRANSLATIONS",
      "ROLE_EXTERNAL_PAYMENT_PROPERTIES_SET_UP",
    ],
  },
  {
    path: APP_ROUTES.translations.create,
    component: TranslationsCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_TRANSLATIONS",
  },
  {
    path: APP_ROUTES.translations.update,
    component: TranslationsUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: [
      "ROLE_MODIFY_TRANSLATIONS",
      "ROLE_EXTERNAL_PAYMENT_PROPERTIES_SET_UP",
    ],
  },
  {
    path: APP_ROUTES.users.index,
    component: Users,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_SUPERADMIN", "ROLE_VIEW_USERS"],
  },
  {
    path: APP_ROUTES.users.create,
    component: UsersCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_SUPERADMIN", "ROLE_MODIFY_USERS"],
  },
  {
    path: APP_ROUTES.users.update,
    component: UsersUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_SUPERADMIN", "ROLE_MODIFY_USERS"],
  },
  {
    path: APP_ROUTES.credentials.index,
    component: CredentialsPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_SUPERADMIN",
  },
  {
    path: APP_ROUTES.credentials.info,
    component: CredentialInfoPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_SUPERADMIN",
  },
  {
    path: APP_ROUTES.credentials.create,
    component: CredentialsCreatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_SUPERADMIN",
  },
  {
    path: APP_ROUTES.credentials.update,
    component: CredentialsUpdatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_SUPERADMIN",
  },
  {
    path: APP_ROUTES.settings.index,
    component: SettingsPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_SETTINGS",
  },
  {
    path: APP_ROUTES.settings.info,
    component: SettingInfoPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_SETTINGS",
  },
  {
    path: APP_ROUTES.settings.create,
    component: SettingsCreatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_SETTINGS",
  },
  {
    path: APP_ROUTES.settings.update,
    component: SettingsUpdatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_SETTINGS",
  },
  {
    path: APP_ROUTES.settings.duplicate,
    component: SettingsDuplicatePage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_SETTINGS",
  },
  {
    path: APP_ROUTES.requestHistory,
    component: RequestHistoryPage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_VIEW_HISTORY",
  },
  {
    path: APP_ROUTES.gateWay.whitelist.index,
    component: GateWayWhiteListPage,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_GATEWAY_IP"],
  },
  {
    path: APP_ROUTES.gateWay.whitelist.create,
    component: GateWayWhiteListCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_GATEWAY_IP"],
  },
  {
    path: APP_ROUTES.gateWay.whitelist.update,
    component: GateWayWhiteListUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: ["ROLE_MODIFY_GATEWAY_IP"],
  },
  {
    path: APP_ROUTES.paymentInstrumentManagement.index,
    component: PaymentInstrumentManagementPage,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_SUPERADMIN", "ROLE_VIEW_EXTERNAL_CUSTOMER_IDENTIFIERS", "ROLE_MODIFY_EXTERNAL_CUSTOMER_IDENTIFIERS"],
  },
  {
    path: APP_ROUTES.statusReasons.index,
    component: StatusReasonsPage,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_SUPERADMIN", "ROLE_MODIFY_STATUS_REASONS"],
  },
  {
    path: APP_ROUTES.statusReasons.create,
    component: StatusReasonsCreatePage,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_SUPERADMIN", "ROLE_MODIFY_STATUS_REASONS"],
  },
  {
    path: APP_ROUTES.statusReasons.update,
    component: StatusReasonsUpdatePage,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_SUPERADMIN", "ROLE_MODIFY_STATUS_REASONS"],
  },
  {
    path: APP_ROUTES.statusReasonsConnection.index,
    component: StatusReasonsConnectionPage,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_SUPERADMIN", "ROLE_MODIFY_STATUS_REASONS"],
  },
  {
    path: APP_ROUTES.statusReasonsConnection.create,
    component: StatusReasonsConnectionCreatePage,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_SUPERADMIN", "ROLE_MODIFY_STATUS_REASONS"],
  },
  {
    path: APP_ROUTES.statusReasonsConnection.update,
    component: StatusReasonsConnectionUpdatePage,
    exact: true,
    privateRoute: true,
    accessTo: ["ROLE_SUPERADMIN", "ROLE_MODIFY_STATUS_REASONS"],
  },
  {
    path: APP_ROUTES.merchantPendingTransactionsMonitoring.index,
    component: MerchantPendingTransactionsMonitoringPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_MONITORING",
  },
  {
    path: APP_ROUTES.merchantPendingTransactionsMonitoring.create,
    component: MerchantPendingTransactionsMonitoringCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_MONITORING",
  },
  {
    path: APP_ROUTES.merchantPendingTransactionsMonitoring.update,
    component: MerchantPendingTransactionsMonitoringUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_MONITORING",
  },
  {
    path: APP_ROUTES.merchantSuccessRateMonitoring.index,
    component: MerchantSuccessRateMonitoringPage,
    exact: true,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_MONITORING",
  },
  {
    path: APP_ROUTES.merchantSuccessRateMonitoring.create,
    component: MerchantSuccessRateMonitoringCreatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_MONITORING",
  },
  {
    path: APP_ROUTES.merchantSuccessRateMonitoring.update,
    component: MerchantSuccessRateMonitoringUpdatePage,
    exact: false,
    privateRoute: true,
    accessTo: "ROLE_MODIFY_MERCHANT_MONITORING",
  },
];

export default (
  <Switch>
    {routes.map((route) => {
      const { privateRoute, ...rest } = route;
      const RouteComponent = !!privateRoute ? PrivateRoute : Route;
      return <RouteComponent key={route.path} {...rest} />;
    })}
    <Route component={PageNotFound} />
  </Switch>
);
