import React from "react";
import { OverflowMenu, OverflowMenuItem } from "carbon-components-react";
import { DotsMenuItem, DotsMenuProps } from "./types";
import classNames from "classnames";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { useHistory } from "react-router-dom";

const DotsMenu: React.FC<DotsMenuProps> = ({ items, clickParams = {}, menuClassName = '' }) => {
  const getLinkWrapper = (data: any, getLink: any) => {
    let result = getLink ? getLink(data) : "";
    return result;
  }
  
  return (
    <OverflowMenu  menuOptionsClass={menuClassName} className={"dots-menu"} menuOffset={{ left: -100, top: -40 }}>
      {items.map(({ icon, IconComponent, onClick, title, type, accessTo, getLink }: DotsMenuItem) => {
        return (
          <RoleWrapper accessTo={accessTo}>
            <OverflowMenuItem
              itemText={
                <div
                  className={classNames(
                    "dots-menu__item",
                    type && `dots-menu__item--${type}`
                  )}
                >
                  {icon && <span>{icon}</span>}
                  {IconComponent && <IconComponent />}
                  <span>{title}</span>
                </div>
              }
              href = {getLinkWrapper(clickParams, getLink)}
              onClick={(e: any) => {
                onClick && onClick(clickParams);
                e.preventDefault()
              }}
              onContextMenu={(e: any) => {
                if (getLink) {
                  e.preventDefault();
                  window.open(getLinkWrapper(clickParams, getLink), "_blank")
                }
              } }
              style = {{textDecoration: "unset"}}
            />
          </RoleWrapper>
        );
      })}
    </OverflowMenu>
  );
};

export default DotsMenu;
