import { Column as ColumnType } from "react-table";
import React, { useMemo, useRef } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { entityTypes, actionTypes } from "modules/actionLog/constants";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { TableColumnType } from "modules/table/types";
import { TextWrap16 } from "@carbon/icons-react";
import { filterOptions } from "./constants"

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("user"),
        id: "userEmail",
        accessor: "userEmail",
        width: 200,
        mobileVisible: true,
        isMobileTitle: true,
      },
      {
        Header: _t("entity_name"),
        id: "entityName",
        accessor: "entityName",
      },
      {
        Header: _t("action_type"),
        id: "actionType",
        accessor: "actionType",
        mobileVisible: true,
      },
      {
        Header: _t("entity_type"),
        id: "entityType",
        accessor: "entityType",
        mobileVisible: true,
      },
      {
        Header: _t("date"),
        id: "date",
        accessor: "date",
        mobileVisible: true,
      },
      {
        Header: _t("user_ip"),
        id: "userIp",
        accessor: "userIp",
        mobileVisible: true,
      },
      {
        Header: _t("action"),
        id: "action",
        accessor: "action",
        mobileVisible: true,
        isMobileHidden: true,
        width: 200,
        Cell: ({column, row}: any) => {
          const ref = useRef<HTMLDivElement>(null)
          const width = ref.current?.offsetWidth || 0

          const toggleClass = () => {
            const el = ref.current

            if (el) {
              el.classList.toggle("action-cell--open")
            }
          }
          
          return (
          <div ref={ref} className="action-cell" >
            {(column.width < width) && 
              <TextWrap16 style={{flexShrink: 0}} onClick={toggleClass}/>
            }
            {row.original?.action}

          </div>
          )
        }
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans()
  const selectFieldsForSearch = filterOptions(_t )
  const entityTypesOptions = arrayOptionsMap(entityTypes, {
    labelKey: "name",
    valueKey: "id",
  });
  const actionTypesOptions = arrayOptionsMap(actionTypes, {
    labelKey: "name",
    valueKey: "id",
  });
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.entityType({
        componentProps: {
          items: entityTypesOptions,
        },
      }),
      fields.filters.actionType({
        componentProps: {
          items: actionTypesOptions,
        },
      }),
      fields.filters.filterValue(),
      fields.filters.filterField({
        componentProps: {
          items: selectFieldsForSearch,
        },
      }),
      fields.filters.date(),
      fields.general.submitBtn(),
    ];
  }, []);
  return formConfig;
};
