import { TransType } from "system/translations/types";

export const filterOptions = (_t: TransType) => [
  { label: _t("merchant_client_id"), value: "merchantClientId" },
  { label: _t("card_number"), value: "cardNum" },
  // { label: _t("uuid"), value: "transactionUUID" },
  { label: _t("uuids"), value: "transactionUUIDs" },
  { label: _t("customer_email"), value: "customerEmail" },
  { label: _t("merchant_order_no"), value: "referenceNo" },
  { label: `${_t("user")} ${_t("id")}`, value: "customerId" },
  { label: _t("psp_order_no"), value: "gatewayTransactionId" },
];

export const expandedItems = (_t: TransType) => [
  { title: _t("payment_method"), accessor: "paymentMethod" },
  { title: _t("client"), accessor: "client.name" },
  { title: _t("merchant_client_id"), accessor: "client.merchantClientId" },
  { title: _t("country"), accessor: "client.country" },
];
export const exportFields = (_t: TransType) => [
  { label: _t("amount"), value: "amount" },
  { label: _t("country"), value: "billingCountry" },
  { label: _t("converted_amount"), value: "convertedAmount" },
  { label: _t("created_date"), value: "createdDate" },
  { label: _t("updated_date"), value: "updatedDate" },
  { label: _t("credit_card"), value: "creditCard" },
  { label: _t("currency"), value: "currency" },
  { label: _t("custom_uuid"), value: "customUuid" },
  { label: _t("gateway_name"), value: "gatewayName" },
  { label: _t("gateway_transaction_id"), value: "gatewayTransactionId" },
  { label: _t("ipn"), value: "ipn" },
  { label: _t("message"), value: "message" },
  { label: _t("payment_method"), value: "paymentMethod" },
  { label: _t("reference_number"), value: "referenceNumber" },
  { label: _t("refundable"), value: "refundable" },
  { label: _t("status"), value: "status" },
  { label: _t("transaction_details"), value: "transactionDetails" },
  { label: _t("transactionUUID"), value: "transactionUUID" },
  { label: _t("type"), value: "type" },
];
