import React, { useEffect, useMemo, useState } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useModuleData } from "./hooks";
import { arrayOptionsMap } from "system/helpers/helperFunctions";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { useGetPaymentMethodCredentials } from "modules/merchantPayments/apiHooks";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { TableColumnType } from "modules/table/types";
import Toggle from "components/atoms/Toggle";
import Loader from "components/atoms/Loader";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import InfoTag from "components/atoms/InfoTag";

export const usePaymentTypes = () => {
  const { _t } = useTrans();
  return [
    { label: _t("deposit"), value: "deposit" },
    { label: _t("withdrawal"), value: "withdrawal" },
  ];
};

export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();

  const activeStatusColumn = [{
    Header: _t("status"),
    mobileVisible: true,
    Cell: (props: any) => {
      if (props?.variables?.id === props?.row?.original?.id && 
        props?.row?.original?.active !== props?.variables?.active &&
        props.paymentType === props?.variables.paymentType
        ) {        
        return <Loader />
      }
      const label = props?.row?.original?.active ? _t("active") : _t("inactive")
      const status = props?.row?.original?.active ? "active" : "deactivated"

      return (
        <RoleWrapper 
          accessTo={"ROLE_CHANGE_PM_STATUS"}
          defaultComponent={<InfoTag type={"secondary"} status={status} label={label} />}
        >
          <Toggle
            key={"Toggle" + props?.row?.original?.id + props.paymentType}
            id={"Toggle" + props?.row?.original?.id + props.paymentType}
            value={props?.row?.original?.active}
            onChange={(e) => {          
              props.onActionUpdate(props?.row?.original);
            }}
            labelA = {_t("inactive")}
            labelB = {_t("active")}
            withControlledValue = {true}
          />
        </RoleWrapper>
      );
    },
  }]
  
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("method"),
        id: "method",
        accessor: "method",
        isMobileTitle: true,
        mobileVisible: true,
      },
      {
        Header: _t("provider"),
        accessor: "provider.name",
        id: "provider.name",
        mobileVisible: true,
      },
      {
        Header: _t("credential"),
        accessor: "credential.key",
        id: "credential.key",
        mobileVisible: true,
      },
      {
        Header: _t("mask_sensitive_data"),
        id: "needToMaskSensitiveData",
        mobileVisible: true,
        Cell: (props: any) =>
          `${props?.row?.original?.needToMaskSensitiveData}`,
      },

      ...activeStatusColumn,
      {
        maxWidth: 40,
        Header: "",
        id: "actionMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <DotsMenu
              clickParams={{
                id: props?.row?.original?.id,
                allData: {
                  ...props?.row?.original,
                  paymentType: props?.paymentType,
                },
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    [activeStatusColumn]
  );

  return columns;
};

export const useCreateEditConfig = ({
  merchantId,
  isEdit,
  initialValues,
  editMethodOptions,
  editProviders,
}: {
  merchantId: number;
  isEdit: boolean;
  initialValues?: any;
  editMethodOptions?: any;
  editProviders?: any;
}): FormConfigType => {
  const { _t } = useTrans();
  const [type, setType] = useState("");
  const {
    depositMethodsOptions,
    withdrawalMethodsOptions,
    depositMethods = [],
    withdrawalMethods = [],
  } = useModuleData(merchantId);
  const getMethodsOptions = (type: string) =>
    isEdit
      ? editMethodOptions
      : type === "deposit"
      ? depositMethodsOptions
      : withdrawalMethodsOptions;
  const {
    mutate: getCredentials,
    data: credentials = [],
    isLoading,
  } = useGetPaymentMethodCredentials();
  const credentialsOptions = arrayOptionsMap(credentials, {
    labelKey: "key",
    valueKey: "id",
  });
  const editProvidersOptions = arrayOptionsMap(editProviders, {
    labelKey: "code",
    valueKey: "id",
  });
  const paymentTypes = usePaymentTypes();
  const getObjectFromArray = (
    array: any[],
    searchKey: string,
    comparativeValue: any
  ) => {
    let res = null;
    array?.forEach((item: any) => {
      if (item?.[searchKey] === comparativeValue) {
        res = item;
      }
    });
    return res;
  };
  useEffect(() => {
    if (initialValues?.paymentGateway) {
      getCredentials(initialValues?.paymentGateway);
    }
  }, [initialValues?.paymentGateway]);
  const fields = useDefaultFieldsConfig();

  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.paymentType({
        componentProps: {
          items: paymentTypes,
          disabled: isEdit,
          onFieldChange: (value: any, values: any, form: any) => {
            setType(value);
            form.setFieldValue("paymentMethod", undefined);
            form.setFieldValue("paymentGateway", undefined);
            form.setFieldValue("credential", undefined);
            form.setFieldTouched("paymentMethod", false);
            form.setFieldTouched("paymentGateway", false);
            form.setFieldTouched("credential", false);
          },
        },
      }),
      {
        component: SelectWithSearch,
        name: "paymentMethod",
        componentProps: (form: any) => {
          const methodsOptions = getMethodsOptions(form?.values?.paymentType);
          return {
            items: methodsOptions,
            labelText: _t("payment_method"),
            onFieldChange: (value: any, values: any, form: any) => {
              form.setFieldValue("paymentGateway", undefined);
              form.setFieldValue("credential", undefined);
              form.setFieldTouched("paymentGateway", false);
              form.setFieldTouched("credential", false);
            },
            disabled: isEdit,
          };
        },
        columnParams: {
          md: 16,
          lg: 16,
        },
      },
      {
        component: SelectWithSearch,
        name: "paymentGateway",
        componentProps: (form: any) => {
          const methods =
            form?.values?.paymentType === "deposit"
              ? depositMethods
              : withdrawalMethods;
          const selectedMethod: any = getObjectFromArray(
            methods,
            "id",
            form?.values?.paymentMethod
          );
          const options = arrayOptionsMap(selectedMethod?.providers, {
            labelKey: "name",
            valueKey: "id",
          });
          
          return {
            onFieldChange: (value: any, values: any, form: any) => {
              form.setFieldValue("credential", undefined);
              form.setFieldTouched("credential", false);
              getCredentials(value);
            },
            labelText: _t("payment_provider"),
            items: isEdit ? editProvidersOptions : options,
            disabled: !form?.values?.paymentMethod,
          };
        },
        columnParams: {
          md: 16,
          lg: 16,
        },
      },
      {
        component: SelectWithSearch,
        name: "credential",
        componentProps: (form: any) => {
          return {
            items: credentialsOptions,
            labelText: _t("payment_credentials"),
            disabled: !form?.values?.paymentGateway,
            isLoading: isLoading,
          };
        },
        columnParams: {
          md: 16,
          lg: 16,
        },
      },
      fields.formFields.needToMaskSensitiveData(),
      fields.formFields.activeToggle(),
      fields.general.saveBtn(),
    ];
  }, [
    type,
    depositMethods,
    withdrawalMethods,
    credentials,
    initialValues,
    isEdit,
    editMethodOptions,
    editProviders,
  ]);
  return formConfig;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const selectFieldsForSearch = [
    {label: _t("method"), value: "method"}, 
    {label:_t("provider"), value: "provider[name]"}
  ]
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.filterWithSelectFieldForInput({
        componentProps: {
          showSearch: true,
          searchIconDescription: _t("search"),
          selectItems: selectFieldsForSearch,
          selectValue: selectFieldsForSearch[0],
        },
      }),
    ];
  }, []);
  return formConfig;
};
