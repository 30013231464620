import React, { useMemo, useState } from "react";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import Table from "modules/table";
import { useTrans } from "system/translations/hooks";
import {
  useFilters,
  useFormFilters,
  useListPagination,
  useListSort,
} from "system/helpers/hooks";
import { useGetActionLog } from "modules/actionLog/apiHooks";
import {
  useTableColumns,
  useSearchFormConfig,
} from "modules/actionLog/constantHooks";
import config from "system/config";
import { format } from "date-fns";
import { useModal } from "modules/modal/hooks";
import { Button } from "carbon-components-react";
import { Filter16, Search16 } from "@carbon/icons-react";

const ActionLog: React.FC = () => {
  const { hideModal } = useModal();
  const { _t } = useTrans();
  const filterInitialValues = useMemo(
    () => ({
      fromDate: format(new Date(), config.dateFormat),
      toDate: format(new Date(), config.dateFormat),
      filterField: "userEmail"
    }),
    []
  );
  const { sort, setSort } = useListSort({});
  const { pagination, setPagination } = useListPagination(
    config.defaultInitialPagination
  );
  const searchFormConfig = useSearchFormConfig();
  const normalizeReqData = (data: any, filtersInitial: any = {}) => {
    const normalizeDate = {
      ...data,
    };
    if (data?.date) {
      normalizeDate.fromDate = data?.date?.fromDate;
      normalizeDate.toDate = data?.date?.toDate;
    } else if (isMobile && !data?.date) {
      normalizeDate.fromDate = filtersInitial.fromDate;
      normalizeDate.toDate = filtersInitial.toDate;
    }
    !isMobile && delete normalizeDate.date;
    delete normalizeDate.searchFrom;

    return normalizeDate
  }
  const { 
    isMobile,
    showFilters,
    mobileSearchFormConfig,
    filters,
    setFilters,
    filtersMobile,
  } = useFormFilters(
    filterInitialValues,
    searchFormConfig,
    FormBuilder,
    normalizeReqData
  )
  const onFilterSubmit = async (data: any) => {
    const normalizeDate = normalizeReqData(data)

    normalizeDate[normalizeDate.filterField] = normalizeDate.filterValue

    delete normalizeDate.filterField
    delete normalizeDate.filterValue

    setPagination(config.defaultInitialPagination);
    isMobile
      ? setFilters({...normalizeDate, ...filtersMobile})
      : setFilters(normalizeDate);
    hideModal('filters');
  };
 
  const requestParams = useMemo(
    () => ({ ...filters, ...pagination, ...sort }),
    [filters, pagination, sort]
  );
  const { data, isLoading } = useGetActionLog(requestParams);
  const columns = useTableColumns();

  return (
    <Card title={_t("action_log")}>
      {!isMobile 
      ? <FormBuilder
        showSubmit={false}
        formItemsConfig={searchFormConfig}
        formProps={{
          submitBtnLabel: _t("apply_filters"),
          onSubmit: onFilterSubmit,
          initialValues: filterInitialValues,
        }}
      />
      : <>
          <Button
          kind="ghost"
          style={{ color: "#252C32" }}
          onClick={() => showFilters()}
          renderIcon={Filter16}
          >
            {_t("filters")}
          </Button>
          <div className="mobile__search-form">
            <FormBuilder
              showSubmit={true}
              formItemsConfig={mobileSearchFormConfig}
              formProps={{
                submitBtnLabel: ' ',
                onSubmit: onFilterSubmit,
                initialValues: filterInitialValues,
                renderIcon: Search16,
                hasIconOnly: true,
                // values: filters
              }} 
            />
          </div>
        </>
    }
      <Table
        totalRecords={data?.totalElements}
        onPagination={setPagination}
        columns={columns}
        onSort={setSort}
        isLoading={isLoading}
        data={data?.content || []}
        isPagination
        pageIndex={pagination.page}
        pageSize={pagination.perPage}
        isMobileExpand={true}
      />
    </Card>
  );
};

export default ActionLog;
