import React, { useMemo, useState } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { TableColumnType } from "modules/table/types";
import { useGetConfirmationsToChange, useModuleData, useModuleDataForSearch } from "./hooks";
import Toggle from "components/atoms/Toggle";
import { Link } from "carbon-components-react";
import { AttributeType, MerchantAndRuleStateType, OrchestrationFormConfigCallBack, StrategyType } from "./types";
import config from "./constants";
import Select from "components/atoms/Select";
import UniversalFormItem from "components/molecules/UniversalFormItem";
import { Checkmark16 } from "@carbon/icons-react";
import Input from "components/atoms/Input";
import SelectWithSearch from "components/atoms/SelectWithSearch";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import InfoTag from "components/atoms/InfoTag";


export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();

  const activeStatusColumn = [{
    maxWidth: 140,
    Header: _t("status"),
    mobileVisible: true,
    Cell: (props: any) => {
      const activationStatus = props?.row?.original?.active === 'true' ? true : false;
      const label = activationStatus ? _t("active") : _t("inactive")
      const status = activationStatus ? "active" : "deactivated"
      
      return (
        <RoleWrapper
          accessTo={"ROLE_MODIFY_ORCHESTRATOR_CONFIGURATION"}
          defaultComponent={<InfoTag type={"secondary"} status={status} label={label} />}
        >
          <Toggle
            key={"Toggle" + props?.row?.original?.id}
            id={"Toggle" + props?.row?.original?.id}
            value={activationStatus}
            onChange={(e) => {
              props.onActionUpdate({...props?.row?.original, active: e})
            }}
            labelA={_t("inactive")}
            labelB={_t("active")}
            withControlledValue={true}
          />
        </RoleWrapper>
      );
    },
  }]

  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("rule_set_name"),
        accessor: "ruleName",
        id: "ruleName",
        Cell: ({ row, onUpdate }: any) => {
          return (
            <RoleWrapper accessTo={"ROLE_MODIFY_ORCHESTRATOR_CONFIGURATION"} defaultComponent={row.original?.ruleName}>
              <Link
                onClick={() => {
                  onUpdate({
                    id: row.original?.id,
                  });
                }}
              >
                {row.original?.ruleName}
              </Link>
          </RoleWrapper> 
          )
        }
      },
      {
        Header: _t("rule_type"),
        accessor: "ruleType",
        id: "ruleType",
      },
      {
        Header: _t("merchant"),
        accessor: "merchant.name",
        id: "merchant",
      },
      {
        Header: _t("method"),
        accessor: "paymentMethod",
        id: "paymentMethod",
      },
      ...activeStatusColumn,
      {
        maxWidth: 40,
        Header: "",
        id: "UpdateDeleteMenu",
        Cell: (props: any) => {
          return (
            <RoleWrapper accessTo={"ROLE_MODIFY_ORCHESTRATOR_CONFIGURATION"}>
              <DotsMenu
                clickParams={{
                  id: props?.row?.original?.id,
                  allData: props?.row?.original,
                }}
                items={props?.actionMenuItems}
              />
            </RoleWrapper>
          );
        },
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans()
  const fields = useDefaultFieldsConfig();
  const providerConstraintsStatuses = config.statuses;
  const { merchantsOptions, paymentMethodsOptions } = useModuleDataForSearch();

  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.merchantId({
        componentProps: {
          items: merchantsOptions,
        },
      }),
      fields.filters.paymentMethod({
        componentProps: {
          items: paymentMethodsOptions,
          label: _t("method")
        },
      }),
      fields.filters.active({
        componentProps: {
          items: providerConstraintsStatuses,
        },
      }),
      fields.filters.filterValue({
        componentProps: {
          showSearch: true,
          type: "submit",
        },
        columnParams: {
          md: 6,
          lg: 6,
        },
      }),
      fields.general.submitBtn()
    ];
  }, [merchantsOptions, paymentMethodsOptions]);
  return formConfig;
};

export const useCreateEditConfig = ({
  isEdit,
  setMerchantAndRuleState = (value: any) => {},
  merchantAndRuleState,
} : {
  isEdit: boolean,
  setMerchantAndRuleState?: React.Dispatch<React.SetStateAction<MerchantAndRuleStateType>>
  merchantAndRuleState: MerchantAndRuleStateType
},): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const ruleTypes = config.ruleType
  const { selectedMerchantId } = merchantAndRuleState
  const { 
    paymentMethodsByMerchantOptions,
    merchantsOptionsUpdate,
    templatesTypesOptions
  } = useModuleData(merchantAndRuleState);
  const { onChangeMerchantConfirmHandler, onChangeRuleTypeConfirmHandler } = useGetConfirmationsToChange()

  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.name({
        name: "ruleName",
        componentProps: {
          labelText: _t('rule_set_name')
        },
        columnParams: {
          lg: 5,
          md: 4,
        },
      }),
      fields.formFields.selectWithSearch({
        name: "ruleType",
        componentProps: {
          items: ruleTypes,
          light: false,
          disabled: isEdit,
          labelText: _t("rule_type"),
          placeholderText: _t("select_rule_type"),
          onConfirmChange: onChangeRuleTypeConfirmHandler,
          onFieldChange: async (value: any, values: any, formChangers: any) => {
            setMerchantAndRuleState((prevState) => ({
              ...prevState,
              selectedRuleType: value || null
            }))
            if (value === 'onDisplay') {
              await formChangers.setFieldValue("templateType", "")
              await formChangers.setFieldTouched("templateType", false)
            }
          }
        },
        columnParams: {
          lg: 5,
          md: 4,
        },
      }),
      fields.formFields.merchantIdSingle({
        name: 'merchant',
        componentProps: {
          items: merchantsOptionsUpdate,
          light: false,
          placeholderText: _t("select_merchant"),
          disabled: isEdit,
          onConfirmChange: onChangeMerchantConfirmHandler,
          onFieldChange: (value: any, values: any, formChangers: any) => {
            !isEdit && setMerchantAndRuleState((prevState) => ({
              ...prevState,
              selectedMerchantId: value?.id || null
            }))
          }
        },
        columnParams: {
          lg: 5,
          md: 4,
        },
      }),
      fields.formFields.selectWithSearch({
        name: "paymentMethod",
        componentProps: {
          items: paymentMethodsByMerchantOptions,
          light: false,
          labelText: _t("routing_key"),
          placeholderText: _t("select_routing_key"),
          disabled: !selectedMerchantId
        },
        columnParams: {
          lg: 5,
          md: 4,
        },
      }),
      {
        component: SelectWithSearch,
        name: 'templateType',
        componentProps: (form: any) => {
          return {
            items: templatesTypesOptions,
            light: false,
            labelText: _t("template_type"),
            placeholderText: _t("select_template_type"),
            disabled: !!form?.values?.ruleType && form?.values?.ruleType === "onDisplay"
          }
        },
        columnParams: {
          lg: 5,
          md: 4,
        },
      },
      fields.formFields.activeToggle({
        columnParams: {
          lg: 2,
          className: 'align-toggle'
        }
      }),
      fields.general.saveBtn({
        columnParams: {
          className: 'tree-view-rule__save-changes-btn'
        },
      }),
    ];
  }, [merchantsOptionsUpdate, paymentMethodsByMerchantOptions]);
  return formConfig;
};

export const useCreateEditConfigAttributes = (merchantAndRuleState: MerchantAndRuleStateType): OrchestrationFormConfigCallBack => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const { paymentGatewaysOptions, merchantPaymentMethodOptions } = useModuleData(merchantAndRuleState);


  const useGetOrchestartionFormConfig = (type: string, initialValue: any): FormConfigType => {
    const [strategyType, setStrategyType] = useState<StrategyType>(initialValue.strategyType)

    const strategyFormConfig = useMemo((): FormConfigType => {
      switch (strategyType) {
        case 'direct':
          return [
            fields.formFields.paymentGatewayCode({
              name: 'depositSettings.0.paymentMethod',
              componentProps: {
                labelText: _t('payment_provider'),
                items: paymentGatewaysOptions,
                light: true,
                onFieldChange: (value: any, values: any, formChangers: any) => {
                  formChangers.setFieldValue('depositSettings.0.merchantPaymentMethod', undefined)
                }
              },
              columnParams: {
                className: "change-value-dialog-window--direct",
                lg: 8,
                md: 4,
              }
            }),
            {
              component: SelectWithSearch,
              name: 'depositSettings.0.merchantPaymentMethod',
              componentProps: (form: any) => {
                const depositSettingsValue = form.getFieldProps(`depositSettings`)?.value?.[0]
                const selectedProvider = depositSettingsValue?.paymentMethod
                const selectedMethod = depositSettingsValue?.merchantPaymentMethod
                const warnProps = merchantPaymentMethodOptions?.[selectedProvider]?.find(method => method.value === selectedMethod)?.disabled
                ? {
                    warn: true,
                    warnText: _t('selected_method_is_inactive')
                  }
                : {}
              
                return {
                  labelText: _t('payment_method'),
                  items: merchantPaymentMethodOptions?.[selectedProvider],
                  light: true,
                  disabled: !selectedProvider,
                  ...warnProps
                }
              } ,
              columnParams: {
                className: "change-value-dialog-window--direct",
                lg: 8,
                md: 4,
              },
            },
          ]
        case 'weight': {
          return [
            {
              component: null,
              type: "array",
              name: "depositSettings",
              components: [
                {
                  component: Toggle,
                  name: "paymentMethodSettings.active",
                  componentProps: (form: any, index: any) => {
                    const depositSettings = form.getFieldProps(`depositSettings`).value
                    const sumOfActive = depositSettings.length
                      ? depositSettings.reduce((acc: any, obj: any, idx: number) => acc + ((!!obj.paymentMethodSettings?.active && (idx !== index)) && (+obj.paymentMethodSettings.weightValue || 0)), 0)
                      : 0
                    const item = depositSettings[index];
                    const itemPercentage = ((+item.paymentMethodSettings?.weightValue / (sumOfActive + +item.paymentMethodSettings?.weightValue)) * 100).toFixed(2) + '%';
                    const fieldName = `depositSettings.${index}.paymentMethodSettings.weightValuePercentage`;

                    if (!depositSettings[index]?.paymentMethodSettings?.weightValuePercentage) {
                      if (item.paymentMethodSettings?.active && item.paymentMethodSettings?.weightValue) {
                        form.setFieldValue(fieldName, itemPercentage)
                      } else {
                        form.setFieldValue(fieldName, '0.00%')
                      }
                    } else if (depositSettings[index]?.paymentMethodSettings?.weightValuePercentage && depositSettings[index]?.paymentMethodSettings.weightValue && item?.paymentMethodSettings?.weightValuePercentage !== itemPercentage) {
                      item.paymentMethodSettings?.active && form.setFieldValue(fieldName, itemPercentage)
                    }

                    return {
                      size: 'sm',
                      labelA: '',
                      labelB: '',
                      labelText: '',
                      withControlledValue: true,
                      id: Math.random().toString(36).substr(2, 9),
                      onFieldChange: (value: any, values: any, formChangers: any) => {
                        const newItemValue = value ? +depositSettings[index].paymentMethodSettings.weightValue || 0 : 0
                        depositSettings.forEach((item: any, idx: number) => {
                          if (item.paymentMethodSettings?.active && item.paymentMethodSettings?.weightValue && idx !== index) {
                            formChangers.setFieldValue(
                              `depositSettings.${idx}.paymentMethodSettings.weightValuePercentage`,
                              (((item.paymentMethodSettings.weightValue) / (sumOfActive + newItemValue)) * 100).toFixed(2) + '%'
                            )
                          } else if (value && (sumOfActive || newItemValue) && idx === index) {
                            formChangers.setFieldValue(
                              `depositSettings.${idx}.paymentMethodSettings.weightValuePercentage`,
                              ((newItemValue / (sumOfActive + newItemValue)) * 100).toFixed(2) + '%'
                            )
                          } else {
                            formChangers.setFieldValue(`depositSettings.${idx}.paymentMethodSettings.weightValuePercentage`, '0.00%')
                          }
                        })
                      },
                    }
                  },
                  columnParams: {
                    lg: 1,
                    md: 1,
                    className: 'align-input-without-label--center '

                  }
                },
                {
                  component: SelectWithSearch,
                  name: 'paymentMethod',
                  componentProps: (form: any, index: any) => {
                    const depositSettings = [...form.getFieldProps(`depositSettings`).value]
                    depositSettings.splice(index, 1)
                    const settingSelectedPaymentGateway = depositSettings.map((setting: any) => setting.paymentMethod);

                    const displayedItems = depositSettings.length > 0
                      ? paymentGatewaysOptions.filter((paymentOption: any) => !settingSelectedPaymentGateway.includes(paymentOption.value))
                      : paymentGatewaysOptions

                    return {
                      items: displayedItems,
                      labelText: _t('payment_provider'),
                      light: true,
                      onFieldChange: (value: any, values: any, formChangers: any) => {
                        formChangers.setFieldValue(`depositSettings.${index}.merchantPaymentMethod`, undefined)
                      }
                    }
                  },
                  columnParams: {
                    lg: 4,
                    md: 2
                  },
                },
                {
                  component: SelectWithSearch,
                  name: 'merchantPaymentMethod',
                  componentProps: (form: any, index: any) => {
                    const depositSettingsValue = form.getFieldProps(`depositSettings`)?.value?.[index]
                    const selectedProvider = depositSettingsValue?.paymentMethod
                    const selectedMethod = depositSettingsValue?.merchantPaymentMethod
                    const warnProps = merchantPaymentMethodOptions?.[selectedProvider]?.find(method => method.value === selectedMethod)?.disabled
                    ? {
                        warn: true,
                        warnText: _t('selected_method_is_inactive')
                      }
                    : {}

                    return {
                      labelText: _t('payment_method'),
                      items: merchantPaymentMethodOptions?.[selectedProvider],
                      light: true,
                      disabled: !selectedProvider,
                      ...warnProps
                    }
                  } ,
                  columnParams: {
                    className: "change-value-dialog-window--direct",
                    lg: 4,
                    md: 2,
                  },
                },
                {
                  component: Input,
                  name: 'paymentMethodSettings.weightValue',
                  componentProps: (form: any, index: any) => {
                    const depositSettings = form.getFieldProps(`depositSettings`).value
                    const sumOfActive = depositSettings.length
                      ? depositSettings.reduce((acc: any, obj: any, idx: number) => acc + ((!!obj.paymentMethodSettings?.active && (idx !== index)) && (+obj.paymentMethodSettings.weightValue || 0)), 0)
                      : 0

                    return {
                      withoutRemove: true,
                      labelText: _t("weight_value"),
                      placeholder: _t("weight_value"),
                      light: true,
                      onFieldChange: (value: any, values: any, formChangers: any) => {
                        const newItemValue = depositSettings[index].paymentMethodSettings?.active ? +value : 0

                        depositSettings.forEach((item: any, idx: number) => {
                          if (item.paymentMethodSettings?.active && idx !== index) {
                            formChangers.setFieldValue(
                              `depositSettings.${idx}.paymentMethodSettings.weightValuePercentage`,
                              (((item.paymentMethodSettings.weightValue || 0) / (sumOfActive + newItemValue)) * 100).toFixed(2) + '%'
                            )
                          } else if (item.paymentMethodSettings?.active && idx === index && value) {
                            formChangers.setFieldValue(
                              `depositSettings.${idx}.paymentMethodSettings.weightValuePercentage`,
                              ((value / (sumOfActive + newItemValue)) * 100).toFixed(2) + '%'
                            )
                          } else {
                            formChangers.setFieldValue(`depositSettings.${idx}.paymentMethodSettings.weightValuePercentage`, '0.00%')
                          }
                        })
                      },
                    }
                  },
                  columnParams: {
                    lg: 3,
                    md: 2
                  },
                },
                fields.formFields.weightValue({
                  name: 'paymentMethodSettings.weightValuePercentage',
                  componentProps: {
                    placeholder: '',
                    labelText: '',
                    withoutRemove: true,
                    readOnly: true,
                    light: true,
                  },
                  columnParams: {
                    className: 'align-input-without-label change-value-dialog-window__input-weightValue',
                    lg: 3,
                    md: 1
                  },
                }),
                fields.general.removeBtn()
              ],
              componentProps: {
                addTittle: "Add provider",
                isAddButtonBottom: true,
                onAddNewItem: (data: any) => data.push({ paymentMethodSettings: { active: false, weightValue: '' } }),
                className: 'change-value-dialog-window--weight'
              }
            },
          ]
        }
        case 'priority':
          return [
            {
              component: null,
              type: "draggableArray",
              name: "depositSettings",
              components: [
                {
                  component: Toggle,
                  name: "paymentMethodSettings.active",
                  componentProps: () => {
                    return {
                      size: 'sm',
                      labelA: '',
                      LabelB: '',
                      labelText: '',
                      withControlledValue: true,
                      id: Math.random().toString(36).substr(2, 9)
                    }
                  },
                  columnParams: {
                    lg: 1,
                    md: 1,
                    className: 'align-input-without-label--center'
                  },
                },
                {
                  component: SelectWithSearch,
                  name: 'paymentMethod',
                  componentProps: (form: any, index: any) => {
                    const depositSettings = [...form.getFieldProps(`depositSettings`).value]
                    depositSettings.splice(index, 1)
                    const settingSelectedPaymentGateway = depositSettings.map((setting: any) => setting.paymentMethod);

                    const displayedItems = depositSettings.length > 0
                      ? paymentGatewaysOptions.filter((paymentOption: any) => !settingSelectedPaymentGateway.includes(paymentOption.value))
                      : paymentGatewaysOptions

                    return {
                      items: displayedItems,
                      labelText: _t('payment_provider'),
                      light: true,
                      onFieldChange: (value: any, values: any, formChangers: any) => {
                        formChangers.setFieldValue(`depositSettings.${index}.merchantPaymentMethod`, undefined)
                      }
                    }
                  },
                  columnParams: {
                    // className: "strategy-select",
                    lg: 5,
                    md: 2
                  }
                },
                {
                  component: SelectWithSearch,
                  name: 'merchantPaymentMethod',
                  componentProps: (form: any, index: any) => {
                    const depositSettingsValue = form.getFieldProps(`depositSettings`)?.value?.[index]
                    const selectedProvider = depositSettingsValue?.paymentMethod
                    const selectedMethod = depositSettingsValue?.merchantPaymentMethod
                    const warnProps = merchantPaymentMethodOptions?.[selectedProvider]?.find(method => method.value === selectedMethod)?.disabled
                    ? {
                        warn: true,
                        warnText: _t('selected_method_is_inactive')
                      }
                    : {}
                    return {
                      labelText: _t('payment_method'),
                      items: merchantPaymentMethodOptions?.[selectedProvider],
                      light: true,
                      disabled: !selectedProvider,
                      ...warnProps
                    }
                  } ,
                  columnParams: {
                    className: "change-value-dialog-window--direct",
                    lg: 5,
                    md: 2,
                  },
                },
                fields.general.reorderArrows(),
                fields.general.dragDropBtn(),
                fields.general.removeBtn()
              ],
              componentProps: {
                addTittle: "Add provider",
                isAddButtonBottom: true,
                onAddNewItem: (data: any) => data.push({ paymentMethodSettings: { active: false, weightValue: '' } }),
                className: "change-value-dialog-window--priority"
              },
            },
          ]
        default:
          return []
      }
    }, [paymentGatewaysOptions, strategyType, merchantPaymentMethodOptions])

    const formConfig = useMemo((): FormConfigType => {
      switch (type) {
        case 'condition':
          return [
            fields.formFields.select({
              name: 'property',
              componentProps: {
                labelText: _t('attribute'),
                items: config.attributes,
                placeholderText: _t('select_criteria'),
                onFieldChange: (value: any, values: any, form: any) => {
                  form.setFieldValue("operator", '')
                  form.setFieldValue('value', '')                  
                  form.setFieldTouched('value', false)
                  form.setFieldTouched('operator', false)
                },
                light: true
              },
              columnParams: {
                className: "minWidthSelect",
                lg: 6,
              }
            }),
            {
              component: Select,
              name: 'operator',
              componentProps: (form: any) => {
                const attribute: AttributeType = form.getFieldProps('property').value
                return {
                  items: attribute ? config.condition?.[attribute].conditionOptions : undefined,
                  labelText: _t('conditions'),
                  placeholderText: _t('select_conditions'),
                  light: true,
                  onFieldChange: (value: any, values: any, form: any) => {
                    config.condition?.[attribute]?.conditionOptionsWithAnotherValueComponent?.[value] === 'twoInputs'
                      ? form.setFieldValue('value', ['', ''])
                      : form.setFieldValue('value', '')
                    
                    form.setFieldTouched('value', false)
                  }
                }
              },
              columnParams: {
                lg: 5,
                md: 2,
                className: "minWidthSelect",
              },
            },
            {
              component: UniversalFormItem,
              name: 'value',
              componentProps: (form: any) => {
                const attribute: AttributeType = form.getFieldProps('property').value
                const condition = form.getFieldProps('operator').value
                const component = attribute
                    ? config.condition?.[attribute]?.conditionOptionsWithAnotherValueComponent?.[condition] || config.condition?.[attribute]?.valueComponent
                    : undefined 
                const additionalProps = typeof config.condition?.[attribute]?.additionalData === 'function'
                  ? config.condition[attribute].additionalData?.(_t)
                  : {}

                return {
                  items: attribute ? config.condition?.[attribute]?.valueOptions : undefined,
                  labelText: _t('value'),
                  component: component,
                  light: true,
                  /// multiselect
                  titleText: _t('value'),
                  greyBackground: true,
                  wrapClassPrefix: 'dropdown-full-width',
                  withTitleText: true,
                  previewSelectedItems: true,
                  labelWithAll: false,
                  /// for input
                  withoutRemove: true,
                  ...additionalProps
                }
              },
              columnParams: {
                lg: 5,
                md: 2,
                className: "minWidthSelect",
              },
            },
            fields.general.divider(),
            fields.general.saveBtn({
              componentProps: {
                hasIconOnly: true,
                renderIcon: Checkmark16,
                label: '',
                id: "routingRulesSaveButton"
              },
              emptySpace: {}
            }),
          ];
        case 'strategy':
          return [
            fields.formFields.strategyType({
              componentProps: {
                onFieldChange: (value: any, values: any, form: any) => {
                  if (initialValue.strategyType !== value) {
                    form.setFieldValue(`depositSettings`, [{}])
                    form.setFieldTouched(`depositSettings`, false)
                  } else {
                    form.setFieldValue(`depositSettings`, initialValue.depositSettings)
                    form.setFieldTouched(`depositSettings`, false)
                  }
                  setStrategyType(value)
                },
              }
            }
            ),
            ...strategyFormConfig,
            fields.general.divider(),
            fields.general.saveBtn({
              componentProps: {
                hasIconOnly: true,
                renderIcon: Checkmark16,
                label: '',
                id: "routingRulesSaveButton",
              },
              emptySpace: {}
            }),
          ]
        case 'conditionGroup':
          return [
            {
              component: null,
              type: "array",
              name: "conditions",
              components: [
                {
                  component: Select,
                  name: 'property',
                  componentProps:(form: any, index: any) => {                     
                    return {
                      labelText: _t('attribute'),
                      items: config.attributes,
                      placeholderText: _t('select_criteria'),
                      light: true,
                      onFieldChange: (value: any, values: any, form: any) => {
                        form.setFieldValue(`conditions.${index}.operator`, '')
                        form.setFieldValue(`conditions.${index}.value`, '')
                        form.setFieldTouched(`conditions.${index}.operator`, false)
                        form.setFieldTouched(`conditions.${index}.value`, false)
                      },
                    }
                  },
                  columnParams: {
                    className: "minWidthSelect",
                    lg: 5,
                    md: 3,
                  }
                },
                {
                  component: Select,
                  name: 'operator',
                  componentProps: (form: any, index: any) => {
                    const attribute: AttributeType = form.getFieldProps(`conditions.${index}.property`).value
                    return {
                      items: attribute ? config.condition?.[attribute].conditionOptions : undefined,
                      labelText: _t('conditions'),
                      placeholderText: _t('select_conditions'),
                      light: true,
                      onFieldChange: (value: any, values: any, form: any) => {
                        config.condition?.[attribute]?.conditionOptionsWithAnotherValueComponent?.[value] === 'twoInputs'
                          ? form.setFieldValue(`conditions.${index}.value`, ['', ''])
                          : form.setFieldValue(`conditions.${index}.value`, '')
                        
                        form.setFieldTouched(`conditions.${index}.value`, false)
                      },
                    }
                  },
                  columnParams: {
                    lg: 5,
                    md: 2,
                    className: "minWidthSelect",
                  },
                },
                {
                  component: UniversalFormItem,
                  name: 'value',
                  componentProps: (form: any, index: any) => {
                    const attribute: AttributeType = form.getFieldProps(`conditions.${index}.property`).value
                    const condition = form.getFieldProps(`conditions.${index}.operator`).value
                    const component = attribute
                      ? config.condition?.[attribute]?.conditionOptionsWithAnotherValueComponent?.[condition] || config.condition?.[attribute]?.valueComponent
                      : undefined
                    const additionalProps = typeof config.condition?.[attribute]?.additionalData === 'function'
                      ? config.condition[attribute].additionalData?.(_t)
                      : {}

                    return {
                      items: attribute ? config.condition?.[attribute]?.valueOptions : undefined,
                      labelText: _t('value'),
                      component: component,
                      light: true,
                      /// multiselect
                      titleText: _t('value'),
                      greyBackground: true,
                      wrapClassPrefix: 'dropdown-full-width',
                      withTitleText: true,
                      previewSelectedItems: true,
                      labelWithAll: false,
                      /// for input
                      withoutRemove: true,
                      ...additionalProps
                    }
                  },
                  columnParams: {
                    lg: 5,
                    md: 2,
                    className: "minWidthSelect",
                  },
                },
                fields.general.removeBtn(),
                fields.formFields.operatorChangerButton({
                  componentProps: {
                    className: 'operator-changer-in-row',
                    isConditionsOperator: true
                  },
                  columnParams: {
                    md: 1
                  }
                }),
                fields.general.divider({
                  columnParams: {
                    lg: 14,
                    md: 7,
                    sm: 14,
                    className: 'align-divider--center'
                  },
                })
              ],
              componentProps: {
                addTittle: "Add attribute",
                isAddButtonBottom: true,
                maxAmount: 3,
                onAddNewItem: (data: any) => data.push({})
              }
            },
            fields.general.divider(),
            fields.general.saveBtn({
              componentProps: {
                hasIconOnly: true,
                renderIcon: Checkmark16,
                label: '',
                id: "routingRulesSaveButton"
              },
              emptySpace: {}
            }),
          ]
        default:
          return [];
      }

    }, [strategyType, paymentGatewaysOptions, type]);
    return formConfig
  }

  return useGetOrchestartionFormConfig;
};
