import React, { useEffect, useMemo, useState } from "react";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import { useTrans } from "system/translations/hooks";
import { useSearchFormConfig } from "./constantHooks";
import { Column, Row, Tile } from "carbon-components-react";
import OverflowMenu from "components/atoms/OverflowMenu";
import { useGetMerchantsConfigurations } from "./apiHooks";
import Loader from "components/atoms/Loader";
import { generatePath, useHistory } from "react-router-dom";
import { APP_ROUTES } from "system/router/constants";
import ButtonLink from "components/atoms/ButtonLink";
import { Add16 } from "@carbon/icons-react";
import { isMobile } from "system/config/constant";

const MerchantConfiguration = () => {
  const history = useHistory();
  const { _t } = useTrans();
  const [groupBy, setGroupBy] = useState(_t('merchant'))
  const isGroupByMerchant = groupBy === _t('merchant')
  const requestParams = useMemo(() => (
    { groupingType: isGroupByMerchant 
      ? 'merchants'
      : 'gateway' 
    }
  ), [isGroupByMerchant]); 
  const {data = {}, isLoading} = useGetMerchantsConfigurations(requestParams);
  const [filteredData, setFilteredData] = useState(data)

  useEffect(() => {
    setFilteredData(data)
  }, [data])

  const filterData = (filter: any) => {
    if (!filter) {
      return data
    }
    return Object.keys(filteredData)
      .filter((key) => key.toLowerCase().includes(filter.trim().toLowerCase()))
      .reduce((cur, key) => { return Object.assign(cur, { [key]: filteredData[key] })}, {});
  }
  
  const setColumnOrderPSPToMerchant = (type: string) => {
    setGroupBy(type)
  }

  const searchFormConfig = useSearchFormConfig(setColumnOrderPSPToMerchant, groupBy);

  const onFilterSubmit = (data: any) => {
    setFilteredData(filterData(data.filterValue))
  };

  const onUpdate = ({ merchantId, paymentGateway, id }: any) => {
    const link = generatePath(APP_ROUTES.merchantsBalanceV2.merchantConfiguration.update, {
      merchantId,
      paymentGateway,
      id
    });
    history.push(link, history.location);
  };
  
  return (
    <Card
      title={_t("merchant_configuration_details")}
      subtitle={_t("merchant_configuration_subtitle")}
      titleClassName="card__head__title--with__subtitle"
      extra={
        <ButtonLink
          isExpressive
          renderIcon={Add16}
          linkTo={APP_ROUTES.merchantsBalanceV2.merchantConfiguration.create}
          {...(isMobile 
            ? { className: "button-create__link" } 
            : { title: _t('add_configuration') })}
        />

      }
    >
      <div style={{marginTop: "24px"}}>
      <FormBuilder
          formItemsConfig={searchFormConfig}
          showSubmit={false}
          formProps={{
            submitBtnLabel: _t("apply_filters"),
            onSubmit: onFilterSubmit,
            initialValues: { pspToMerchant: groupBy },
          }}
        />
        {isLoading
        ? <Loader/>
        : ( <Row>
          {Object.keys(filteredData)?.map((item, idx) => {
            
            return (
              <Column lg={{ span: 8 }} md={{ span: 4 }} sm={{ span: 16 }}>
                <Tile
                  className={"bx--link-panel"}
                >
                  <div className="bx--link__container">
                    <div className="bx--link__title-container">
                      <div className="bx--link-title">
                      {item}
                      </div>
                      <div>
                        <OverflowMenu 
                          title={isGroupByMerchant ? "Active PSP(s):" : "Active Merchants:"} 
                          items={filteredData[item]} 
                          amount={filteredData[item].length} 
                          itemKey={isGroupByMerchant ? 'paymentGatewayName' : 'merchantName'}
                          itemsHint={isGroupByMerchant ? 'Choose PSP to set/edit values' : 'Choose Merchant to set/edit values'}
                          onUpdate={onUpdate}
                        />
                      </div>
                    </div>
                  </div>
                </Tile>
              </Column>
            );
         })}
      </Row>)
        }
       
      </div>
    </Card>
  );
};

export default MerchantConfiguration;
