import React, { useEffect } from "react";
import { Column, Grid, Row } from "carbon-components-react";
import { generatePath, useHistory, useParams } from "react-router-dom";
import { useTrans } from "system/translations/hooks";
import Card from "components/atoms/Card";
import FormBuilder from "modules/formBuilder/FormBuilder";
import Loader from "components/atoms/Loader";
import {
  useGetManualRates,
  useGetMerchantsConfigurationsItem,
  useUpdateMerchantsConfigurationsItem
} from "../apiHooks";
import { useTableExchangeRates } from "../constantHooks";
import Table from "modules/table";
import { Add20, RecentlyViewed16 } from "@carbon/icons-react";
import Divider from "components/atoms/Divider";
import { APP_ROUTES } from "system/router/constants";
import { useGetHelpers } from "../hooks";
import { createEditValidation } from "../validation";

const Update: React.FC = () => {
  const { _t } = useTrans();
  const routeParams: any = useParams();
  const { data, isLoading } = useGetMerchantsConfigurationsItem(+routeParams.id);
  const { mutate: onUpdate , data: dataMutate, isLoading: isUpdateLoading } = useUpdateMerchantsConfigurationsItem();
  const { data: manualRates, isLoading: manualRatesLoading } = useGetManualRates(+routeParams.merchantId, routeParams.paymentGateway)
  const columns = useTableExchangeRates()
  const history = useHistory();
  const {
    initialValues,
    showAllManualRates,
    pageTitleName,
    formConfig,
    configurationType,
    addManualRate,
    newManualRates,
    checkDataBeforeSubmit,
    tableCellProps
  } = useGetHelpers({ columns, data, manualRates, manualRatesLoading, onUpdate })

  useEffect(() => {
    if (dataMutate) {
      const link = generatePath(APP_ROUTES.merchantsBalanceV2.merchantConfiguration.update, {
        merchantId: dataMutate.merchantId,
        paymentGateway: dataMutate.paymentGatewayCode,
        id: dataMutate.id
      });
      history.replace(link);
    }
  }, [dataMutate])

  const onSubmit = async (formData: any) => {
    const normalize = { ...formData }
    delete normalize.configurationType
    delete normalize.activeConvertCurrency
    delete normalize.updatetInitialValues
    normalize.parentPaymentGatewayName = normalize.parentPaymentGatewayCode?.paymentGatewayName 
    normalize.parentPaymentGatewayCode = normalize.parentPaymentGatewayCode?.paymentGatewayCode 

    if (normalize.convertCurrency === "") {
      normalize.convertCurrency = null
    } 

    checkDataBeforeSubmit(normalize)    
  };

  if (isLoading || isUpdateLoading) {
    return <Loader formOverlay />;
  }

  return (
    <Grid className="create-update-grid" condensed>
      <Row>
        <Column
          lg={{ span: 8, offset: 4 }}
          md={{ span: 15, offset: 1 }}
          sm={{ span: 16, offset: 0 }}
        >
          <Card title={pageTitleName} titleClassName='card__head__title-break-words'>
            <FormBuilder
              formItemsConfig={formConfig}
              showSubmit={false}
              formProps={{
                submitBtnLabel: _t("save_changes"),
                onSubmit: onSubmit,
                enableReinitialize: true,
                initialValues: initialValues,
                validationSchema: createEditValidation(_t, {
                  isEdit: true,
                }),
              }}
            />
            {configurationType === 'exchangeRate' && (
              <>
                <Divider />
                <div className="form-item__table-header-container">
                  {_t("manual_rates")}
                  <div className="form-item__table-header-add-btn" onClick={addManualRate}>
                    <Add20 /> {_t("set_a_new_manual_rate")}
                  </div>
                </div>
                <Table
                  columns={columns}
                  isLoading={manualRatesLoading}
                  data={newManualRates.slice(0,5)}
                  cellProps={tableCellProps}
                />
                {newManualRates.length > 5 &&
                  <>
                    <Divider />
                    <div className="history-link" onClick={showAllManualRates}>
                      <RecentlyViewed16 style={{ color: 'black' }} />
                      <div className="history-link__text">{_t("view_all_rates")} </div>
                    </div>
                  </>
                }
              </>
            )}

          </Card>
        </Column>
      </Row>
    </Grid>
  );
};

export default Update;
