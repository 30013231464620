import { Link } from "carbon-components-react";
import InfoTag from "components/atoms/InfoTag";
import React, { useEffect, useMemo, useState } from "react";
import { useTrans } from "system/translations/hooks";
import { FormConfigType } from "../formBuilder/types";
import DotsMenu from "components/atoms/DotsMenu";
import { useDefaultFieldsConfig } from "modules/formBuilder/hooks";
import { RoleWrapper } from "modules/admin/components/RoleWrapper";
import { TableColumnType } from "modules/table/types";
import Input from "components/atoms/Input";
import Btn from "components/atoms/Btn";

export const useRedirectType = () => {
  const { _t } = useTrans();
  return [
    { label: _t("post"), value: "POST" },
    { label: _t("get"), value: "GET" },
  ];
};


export const useTableColumns = (): TableColumnType[] => {
  const { _t } = useTrans();
  const columns = useMemo(
    (): TableColumnType[] => [
      {
        Header: _t("id"),
        id: "id",
        accessor: "id",
        maxWidth: 50,
        mobileVisible: true,
      },
      {
        Header: _t("name"),
        id: "name",
        mobileVisible: true,
        isMobileTitle: true,
        Cell: (props: any) => {
          return (
            <RoleWrapper
              defaultComponent={props?.row?.original?.name}
              accessTo={"ROLE_VIEW_MERCHANT_DETAILS"}
            >
              <Link onClick={() => props.showDetails(props.row.original.id, props?.row?.original?.name)}>
                {props?.row?.original?.name}
              </Link>
            </RoleWrapper>
          );
        },
      },
      {
        Header: _t("status"),
        mobileVisible: true,
        Cell: (props: any) => {
          const status = props?.row?.original?.disable
            ? "active"
            : "deactivated";
          return (
            <>
              <InfoTag
                type={"secondary"}
                size={"sm"}
                status={status}
                label={status}
              />
            </>
          );
        },
      },
      {
        Header: _t("sub"),
        Cell: (props: any) => props?.row?.original?.sub.length,
      },
      {
        maxWidth: 40,
        Header: "",
        id: "actionMenu",
        mobileVisible: true,
        Cell: (props: any) => {
          return (
            <DotsMenu
              menuClassName={"dots-menu__max-width"}
              clickParams={{
                id: props?.row?.original?.id,
                allData: props?.row?.original,
              }}
              items={props?.actionMenuItems}
            />
          );
        },
      },
    ],
    []
  );

  return columns;
};

export const useSearchFormConfig = (): FormConfigType => {
  const { _t } = useTrans();
  const fields = useDefaultFieldsConfig();
  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.filters.filterValue({
        componentProps: {
          showSearch: true,
          SearchIconDescription: _t("search"),
        },
        columnParams: {
          md: 6,
          lg: 6,
        },
        name: "name"
      }),
    ];
  }, []);
  return formConfig;
};

export const useCreateEditConfig = (initialValues?: any): FormConfigType => {  
  const { _t } = useTrans()
  const fields = useDefaultFieldsConfig();
  const redirectType = useRedirectType();
  const [ useSeparateUrlForV1Api, setUseSeparateUrlForV1Api ] = useState(false)
  const [ useSeparateUrlV2DepositAndWithdrawal, setUseSeparateUrlV2DepositAndWithdrawal ] = useState(false)
  const [ usePushDepositUrl, setUsePushDepositUrl ] = useState(false)

  useEffect(() => {
    setUseSeparateUrlForV1Api(!!initialValues?.useSeparateUrlForV1Api)
    setUseSeparateUrlV2DepositAndWithdrawal(!!initialValues?.useSeparateUrlV2DepositAndWithdrawal)
    setUsePushDepositUrl(!!initialValues?.usePushDepositUrl)
  }, [JSON.stringify(initialValues)])

  const pushDepositUrlFormConfig = useMemo((): FormConfigType => {
    if (usePushDepositUrl) {
      return [fields.formFields.pushDepositIpnUrl({name: 'ipns.pushDepositIpnUrl'})]
    }

    return []
  }, [usePushDepositUrl]);

  const separateUrlV2DepositAndWithdrawalFormConfig = useMemo((): FormConfigType => {
    switch(true) {
      case useSeparateUrlV2DepositAndWithdrawal:
        return [
          fields.formFields.depositIpnUrl({name: 'ipns.depositIpnUrl'}),
          fields.formFields.withdrawalIpnUrl({name: 'ipns.withdrawalIpnUrl'})
        ]
      default:
        return [
          fields.formFields.ipns({
            name: 'ipns.v2IpnUrl',
            componentProps: {
              labelText:  _t('v2_ipn_url'),
              placeHolderText:  _t('v2_ipn_url'),
            }
          })
        ]
    }
  }, [useSeparateUrlV2DepositAndWithdrawal, useSeparateUrlForV1Api]);

  const separateUrlForApiFormConfig = useMemo((): FormConfigType => {
    switch(true) {
      case useSeparateUrlForV1Api:
        return [
          fields.formFields.v1IpnUrl({name: 'ipns.v1IpnUrl'}),
          ...separateUrlV2DepositAndWithdrawalFormConfig
        ]
      default:
        return [...separateUrlV2DepositAndWithdrawalFormConfig]
    }
  }, [useSeparateUrlForV1Api, separateUrlV2DepositAndWithdrawalFormConfig, initialValues]);

  const formConfig = useMemo((): FormConfigType => {
    return [
      fields.formFields.name(),
      fields.formFields.state(),
      fields.formFields.city(),
      fields.formFields.postcode(),
      fields.formFields.address(),
      fields.formFields.street(),
      fields.formFields.domain(),
      fields.formFields.walletId(),
      fields.formFields.redirectType({
        componentProps: {
          items: redirectType,
        },
      }),
      fields.formFields.ThreeDStatus(),
      fields.formFields.mcc(),
      fields.formFields.descriptor(),
      fields.general.divider(),
      ...pushDepositUrlFormConfig,
      ...separateUrlForApiFormConfig,
      fields.formFields.useSeparateUrlForV1Api({
        componentProps:{
          onFieldChange: (value: any, values: any, form: any) => {
            setUseSeparateUrlForV1Api(value)
          },
        },
        columnParams: {
          lg: 8,
          md: 4,
        },
      }),
      fields.formFields.usePushDepositUrl({
        componentProps:{
          onFieldChange: (value: any, values: any, form: any) => {
            value && form.setFieldValue('ipns.pushDepositIpnUrl', "")
            setUsePushDepositUrl(value)
          },
        },
        columnParams: {
          lg: 8,
          md: 4,
        },
      }),
      fields.formFields.useSeparateUrlV2DepositAndWithdrawal({
        componentProps:{
          onFieldChange: (value: any, values: any, form: any) => {
            if (value && (values.ipns?.depositIpnUrl && values.ipns?.withdrawalIpnUrl) && (values.ipns?.depositIpnUrl.trim() === values.ipns?.withdrawalIpnUrl.trim())) {
              form.setFieldValue('ipns.depositIpnUrl', "")
              form.setFieldValue('ipns.withdrawalIpnUrl', "")
              form.setFieldTouched('ipns.depositIpnUrl', false);
              form.setFieldTouched('ipns.withdrawalIpnUrl', false);
            } 
            setUseSeparateUrlV2DepositAndWithdrawal(value)
          },
        },
      }),
      fields.general.divider(),
      fields.formFields.allowPartialRefund({
        columnParams: {
          lg: 8,
          md: 4,
        },
      }),
      fields.formFields.allowPartialCapture({
        columnParams: {
          lg: 8,
          md: 4,
        },
      }),
      // fields.formFields.apiV2({
      //   columnParams: {
      //     lg: 8,
      //     md: 4,
      //   },
      // }),
      fields.formFields.allowDynamicDescriptor({
        columnParams: {
          lg: 8,
          md: 4,
        },
      }),
      fields.formFields.avsCheck({
        columnParams: {
          lg: 8,
          md: 4,
        },
      }),
      fields.formFields.disableToggle({
        columnParams: {
          lg: 16,
          md: 8,
        },
      }),
      fields.general.saveBtn(),
    ];
  }, [pushDepositUrlFormConfig, separateUrlForApiFormConfig, usePushDepositUrl]);
  return formConfig;
};

export const useSetupSlackChannelConfig = (
  initialValues: any,
  { onConfirm, onRemove, isDeleteLoading }: {
    onConfirm: (newChannel: any) => Promise<void>,
    onRemove: (index: number) => Promise<void>,
    isDeleteLoading: boolean
  }
  ): FormConfigType => {
  const fields = useDefaultFieldsConfig();
  const { _t } = useTrans()
  
  const formConfig = useMemo((): FormConfigType => {
    const channelsLength = initialValues?.length || 0;
    return [
      {
        component: null,
        type: "array",
        name: "channels",
        components: [
          {
            component: Input,
            name: "channelName",
            componentProps: (form: any, index: number) => {
              const { channels } = form.values || {};
              const isChannelUsed = initialValues[index]?.usedChannel;
              const isNewChannel = (channels?.length > channelsLength) && (index === channels.length - 1)
              const canRemoveChannel = !isChannelUsed || isNewChannel
              const canConfirmNewChannel = isNewChannel && channels?.[index]?.channelName?.length !== 0
                  
              return {
                labelText: _t("slack_channel_name"),
                placeholder: _t("slack_channel_name"),
                disabled: Boolean(channels?.[index]?.id),
                isDisabledRemove: !canRemoveChannel || isDeleteLoading,
                isDisabledConfirm: !canConfirmNewChannel,
                onConfirm: () => onConfirm(channels[channels.length - 1]),
                onRemove: !isNewChannel && (() => onRemove(index))
              };
            },
            columnParams: {
              lg: 16,
              md: 16,
            },
          },
        ],
        componentProps: (form: any) => {
          const { channels } = form.values || {};
          const canAddNewChannel = (channels?.length || 0) >= channelsLength;

          return {
            topTitle: _t('slack_channels'),
            addTittle: _t('add_slack_channel'),
            isAddButtonBottom: true,
            isTitleTop: true,
            maxAmount: canAddNewChannel ? channelsLength + 1 : 0,
            onAddNewItem: (newValue: any[]) => newValue.push({channelName: ""}),            
          };
        },
      },
    ];
  }, [initialValues, isDeleteLoading]);
  
  return formConfig;
}